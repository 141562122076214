import * as v from 'valibot'
import type { FormSubmitEvent } from '#ui/types'

export default function () {
  const route = useRoute()
  const localePath = useLocalePath()
  const getRouteBaseName = useRouteBaseName()
  const page = ref(Number(route.query.page) || 1)

  const query = computed(() => ({
    q: route.query.q ?? undefined,
    from: route.query.from ?? undefined,
    to: route.query.to ?? undefined,
    page: route.query.page ?? undefined,
  }))

  watch(page, () => {
    navigateTo(localePath({
      replace: getRouteBaseName(route) === 'events',
      name: 'events',
      query: {
        ...route.query,
        page: page.value > 1 ? page.value : undefined,
      },
    }))
  })

  watch(query, () => {
    page.value = Number(route.query.page) || 1
  })

  const schema = v.object({
    q: v.string(),
  })

  type Schema = v.InferOutput<typeof schema>

  const state = reactive({
    q: route.query.q ? String(route.query.q) : '',
  })

  const onSubmit = (event: FormSubmitEvent<Schema>) => {
    navigateTo(localePath({
      replace: getRouteBaseName(route) === 'events',
      name: 'events',
      query: {
        ...route.query,
        q: event.data.q || undefined,
        page: undefined,
      },
    }))
  }

  return { query, schema, state, onSubmit, page }
}
