<template>
  <div>
    <UForm
      :schema="v.safeParser(schema)"
      :state="state"
      class="flex items-end space-x-4"
      @submit="onSubmit"
      @error="(err) => console.error(err)"
    >
      <UFormGroup
        name="search"
        class="flex-1"
      >
        <UInput
          v-model="state.q"
          size="xl"
          :placeholder="placeholder"
          icon="i-heroicons-magnifying-glass-20-solid"
        />
      </UFormGroup>
      <UFormGroup v-if="events && filters">
        <UButton
          size="xl"
          square
          icon="i-heroicons-funnel"
          @click="isFilterOpen = true"
        />
      </UFormGroup>
    </UForm>
    <EventFilters
      v-if="events && filters"
      v-model="isFilterOpen"
      :min-price="events?.meta.minPrice"
      :max-price="events?.meta.maxPrice"
    />
  </div>
</template>

<script setup lang="ts">
import * as v from 'valibot'

defineProps<{
  events: Record<string, any> | undefined
  filters?: boolean
  placeholder: string
}>()

const { schema, state, onSubmit } = useSearch()

const isFilterOpen = ref(false)
</script>
