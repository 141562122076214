<template>
  <UButton
    color="white"
    :ui="{
      base: '',
      padding: '',
      color: { white: { solid: 'ring-0 shadow' } },
      inline: 'items-start',
    }"
    :to="localePath({ name: 'events-id', params: { id: event.id } })"
  >
    <UCard
      :ui="{
        ring: '',
        divide: '',
        shadow: '',
        header: {
          base: 'h-48 relative',
          padding: '',
        },
        background: 'bg-transparent',
        body: {
          padding: 'p-0 sm:p-0',
        },
        footer: {
          base: '',
          padding: 'p-6',
        },
      }"
    >
      <template #header>
        <NuxtImg
          :src="event.images[0].name"
          provider="upfan"
          :alt="event.name"
          class="size-full rounded-t-lg object-cover brightness-50"
        />
        <div
          v-if="event.season.category.name === 'Music'"
          class="absolute left-4 top-4 size-10 rounded bg-blue-700 shadow-lg"
        >
          <Icon
            name="i-heroicons-musical-note"
            class="size-full p-1.5 text-white"
          />
        </div>
        <div
          v-else-if="event.season.category.name === 'Sport'"
          class="absolute left-4 top-4 size-10 rounded bg-red-700 shadow-lg"
        >
          <Icon
            name="i-material-symbols-sports-soccer"
            class="size-full p-1.5 text-white"
          />
        </div>
        <div v-else />
      </template>
      <template #default>
      <!---
      <h2 class="text-xl font-bold">
        {{ event.name }}
      </h2>
      <p>{{ event.venue.name }}</p>
      <p>{{ DateTime.fromISO(event.startsAt).toLocaleString(DateTime.DATE_FULL) }}</p>
      --->
      </template>
      <template #footer>
        <div class="grid grid-cols-5 gap-4">
          <div class="text-center">
            <p class="text-sm font-bold uppercase text-upfan-600">
              {{ DateTime.fromISO(event.startsAt).monthShort }}
            </p>
            <p class="text-2xl font-bold">
              {{ DateTime.fromISO(event.startsAt).day }}
            </p>
          </div>
          <div class="col-span-4">
            <p class="text-sm font-bold">
              {{ event.name }}
            </p>
            <p class="mt-2 line-clamp-4 text-sm font-normal text-gray-600">
              {{ event.description }}
            </p>
          </div>
        </div>
      <!---
      <div class="flex items-center justify-between">
        <ULink
          :to="`/events/${event.id}`"
          class="text-upfan-400 hover:text-upfan-500"
        >
          View details
        </ULink>
        <UBadge
          :to="`/events/${event.id}`"
          size="lg"
        >
          From {{ priceStartsFrom }}$
        </UBadge>
      </div>
      -->
      </template>
    </UCard>
  </UButton>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon'
import type { Event } from '~/types'

const props = defineProps<{ event: Event }>()

const localePath = useLocalePath()

const priceStartsFrom = computed(() => {
  return props.event.ticketCategories.reduce((acc, curr) => {
    return Math.min(acc, curr.price)
  }, Infinity)
})
</script>
