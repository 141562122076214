<template>
  <UModal
    v-model="model"
  >
    <UCard>
      <div class="flex items-center justify-between">
        <div class="size-8" />
        <h3 class="text-center text-xl">
          {{ $t('events.filters-title') }}
        </h3>
        <UButton
          color="gray"
          variant="ghost"
          icon="i-heroicons-x-mark-20-solid"
          class="-my-1"
          @click="model = false"
        />
      </div>
      <UForm
        :schema="v.safeParser(schema)"
        :state="state"
        class="space-y-4"
        novalidate
        @submit="onSubmit"
        @error="(err) => console.error(err)"
      >
        <UFormGroup
          :label="$t('events.price')"
          name="price"
        >
          <div class="space-y-3">
            <SliderRoot
              v-model="state.price"
              class="relative flex h-4 cursor-pointer touch-none select-none items-center"
              :min="props.minPrice"
              :max="props.maxPrice"
              :step="Number(((props.maxPrice - props.minPrice) / 100).toFixed(2))"
            >
              <SliderTrack class="relative h-2 grow rounded-full bg-gray-200">
                <SliderRange class="absolute h-full rounded-full bg-upfan-500" />
              </SliderTrack>
              <SliderThumb class="block size-5 rounded-full border-2 border-upfan-500 bg-white" />
              <SliderThumb class="block size-5 rounded-full border-2 border-upfan-500 bg-white" />
            </SliderRoot>
            <div class="flex justify-between gap-32">
              <UInput
                v-model="state.price[0]"
                type="number"
                :ui="{ base: 'text-center' }"
              />
              <UInput
                v-model="state.price[1]"
                type="number"
                :ui="{ base: 'text-center' }"
              />
            </div>
          </div>
        </UFormGroup>
        <UButton
          variant="outline"
          block
          @click="resetFilters"
        >
          {{ $t('events.filters-reset') }}
        </UButton>
        <UButton
          type="submit"
          block
        >
          {{ $t('events.filters-submit') }}
        </UButton>
      </UForm>
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
import * as v from 'valibot'
import type { FormSubmitEvent } from '#ui/types'

const model = defineModel<boolean>()
const props = defineProps<{
  minPrice: number
  maxPrice: number
}>()

const schema = v.object({
  price: v.array(v.number()),
})

type Schema = v.InferOutput<typeof schema>

const route = useRoute()

const state = reactive({
  price: [Number(route.query.from) || props.minPrice, Number(route.query.to) || props.maxPrice],
})

async function onSubmit(event: FormSubmitEvent<Schema>) {
  navigateTo({
    replace: true,
    query: {
      ...route.query,
      from: event.data.price[0] === props.minPrice ? undefined : event.data.price[0],
      to: event.data.price[1] === props.maxPrice ? undefined : event.data.price[1],
      page: undefined,
    },
  })
  model.value = false
}

const resetFilters = () => {
  state.price = [props.minPrice, props.maxPrice]
  navigateTo({
    replace: true,
    query: {
      ...route.query,
      from: undefined,
      to: undefined,
      page: undefined,
    },
  })
  model.value = false
}
</script>
